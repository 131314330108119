import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from '@app/layouts/admin/admin.component';
import { BlankComponent } from '@app/layouts/blank/blank.component';
import { AuthGuard } from '@app/helpers/guards/Auth.guard';
import { AclGuard } from '@app/helpers/guards/acl.guard';


const routes: Routes = [
  { path: '', redirectTo: '/auth/signin', pathMatch: 'full', data: { name: 'auth' } },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard, AclGuard],
        data: { name: 'dashboard' }
      },
      {
        path: 'customer',
        loadChildren: () =>
          import('./pages/customer/customer.module').then((m) => m.CustomerModule),
        canActivate: [AuthGuard, AclGuard],
        data: { name: 'customer' }
      },
      {
        path: 'history-trips',
        loadChildren: () =>
          import('./pages/history-trips/history-trips.module').then((m) => m.HistoryTripsModule),
        canActivate: [AuthGuard, AclGuard],
        data: { name: 'historyTrips' }
      },
      {
        path: 'detail-trips',
        loadChildren: () =>
          import('./pages/detail-trips/detail-trips.module').then((m) => m.DetailTripsModule),
        canActivate: [AuthGuard, AclGuard],
        data: { name: 'detailTrips' }
      },
      {
        path: 'inventories',
        loadChildren: () => import('./pages/inventories/inventories.module').then(m => m.InventoriesModule),
        canActivate: [AuthGuard, AclGuard],
        data: { name: 'inventories' }
      },
      {
        path: 'messaging',
        loadChildren: () => import('./pages/messaging/messaging.module').then(m => m.MessagingModule),
        canActivate: [AuthGuard, AclGuard],
        data: { name: 'messaging' }
      },
      {
        path: 'detail-modify',
        loadChildren: () =>
          import('./pages/detail-modify/detail-modify.module').then((m) => m.DetailModifyModule),
        canActivate: [AuthGuard, AclGuard],
        data: { name: 'detailModify' }
      },
      {
        path: 'payment-history',
        loadChildren: () => import('./pages/payment-history/payment-history.module').then(m => m.PaymentHistoryModule),
        canActivate: [AuthGuard, AclGuard],
        data: { name: 'paymentHistory' }
      },
      {
        path: 'operator-settings',
        loadChildren: () =>
          import('./pages/operator-settings/operator-settings.module').then((m) => m.OperatorSettingsModule),
        canActivate: [AuthGuard]
      }
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./pages/auth/auth.module').then((m) => m.AuthModule),
        data: { name: 'auth' },
        canActivate: [AuthGuard]
      },
    ],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
