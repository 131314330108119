import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import { Observable } from 'rxjs';
import { IAuth } from '@app/pages/auth/shared/interfaces/auth.interface';

export interface NavItem {
  id: string;
  displayName: string;
  disabled?: boolean;
  iconName: string;
  route?: string;
  children?: NavItem[];
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  currentUser: Observable<IAuth>;

  menu: NavItem[] = [
    {
      id: 'dashboard',
      displayName: 'Dashboard',
      iconName: '',
      route: '/dashboard'
    },
    {
      id: 'dashboard',
      displayName: 'Reporting',
      iconName: '',
      route: '/dashboard',
    },
    {
      id: 'customer',
      displayName: 'Customer',
      iconName: '',
      route: '/customer',
    },
    {
      id: 'historyTrips',
      displayName: 'Trip History',
      iconName: '',
      route: '/history-trips',
    },
    {
      id: 'paymentHistory',
      displayName: 'Payment History',
      iconName: '',
      route: '/payment-history',
    },
    {
      id: 'inventories',
      displayName: 'Inventory',
      iconName: '',
      route: '/inventories',
    },
    {
      id: 'operatorSettings',
      displayName: 'Operator Settings',
      iconName: '',
      children: [
        // BM-526: Operator - Remove My Profile from menu
        // {
        //   id: 'myProfile',
        //   displayName: 'My Profile',
        //   iconName: '',
        //   route: '/operator-settings/my-profile'
        // },
        {
          id: 'profiles',
          displayName: 'Operator Profile',
          iconName: '',
          route: '/operator-settings/profiles'
        },
        {
          id: 'companyInformation',
          displayName: 'Company Information',
          iconName: '',
          route: '/operator-settings/company-information'
        },
        {
          id: 'dynamicPricing',
          displayName: 'Dynamic Pricing',
          iconName: '',
          route: '/operator-settings/dynamic-pricing'
        },
        {
          id: 'bankingInformation',
          displayName: 'Banking Information',
          iconName: '',
          route: '/operator-settings/banking-information'
        },
        {
          id: 'users',
          displayName: 'Users',
          iconName: '',
          route: '/operator-settings/users',
        },
        {
          id: 'changePassword',
          displayName: 'Change Password',
          iconName: '',
          route: '/operator-settings/change-password'
        }
      ]
    }
  ];
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(private authService: AuthService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.currentUser = this.authService.auth;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  onLogout() {
    this.authService.logout();
  }
}
