import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  // Form login
  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      invitationCode: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
      ],
      password: ['', Validators.required],
      passwordConfirmation: ['', Validators.required],
      termsConditions: [false, Validators.required],
      buslanesFees: [false, Validators.required]
    });
  }

  /**
  * @public
  * @name formControl
  * @description Getter for easy access to form fields
  */
  get formControl() { return this.form.controls; }

  /**
   * @public
   * @name onSubmit
   * @description Submit credential for login
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this._snackBar.open('Please check your input', 'close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
      })

      return;
    }

    this.loading = true;
    // this.authService.login(this.formControl.email.value, this.formControl.password.value)
    //   .pipe(first())
    //   .subscribe(
    //     data => {
    //       this.router.navigate([this.returnUrl]);
    //     },
    //     error => {
    //       this._snackBar.open('Invalid email or password')
    //       this.loading = false;
    //     });
  }

}
