import { AppRole } from '@app/pages/auth/shared/interfaces/auth.interface';

/**
 * ACL Type.
 */
interface AclType {
    [name: string]: (AppRole | '*')[];
}

/**
 * ACL Value.
 */
export const ACL: AclType = {
    auth: ['*'],
    dashboard: ['superuser', 'manager', 'agent'],
    customer: ['superuser', 'manager', 'agent'],
    historyTrips: ['superuser', 'manager', 'agent'],
    detailTrips: ['superuser', 'manager', 'agent'],
    inventories: ['superuser', 'manager', 'agent'],
    messaging: ['superuser', 'manager', 'agent'],
    detailModify: ['superuser', 'manager', 'agent'],
    paymentHistory: ['superuser', 'manager', 'agent'],
    myProfile: ['superuser', 'manager', 'agent'],
    profiles: ['superuser', 'manager', 'agent'],
    companyInformation: ['superuser'],
    bankingInformation: ['superuser'],
    dynamicPricing: ['superuser'],
    users: ['superuser'],
    changePassword: ['superuser', 'manager', 'agent'],
    operatorSettings: ['superuser', 'manager', 'agent']
};