import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { IAuth } from '@app/pages/auth/shared/interfaces/auth.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authSubject: BehaviorSubject<IAuth>;
  public auth: Observable<IAuth>;
  public user: IAuth;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.authSubject = new BehaviorSubject<IAuth>(JSON.parse(localStorage.getItem('buslane-session')));
    this.auth = this.authSubject.asObservable();
  }

  /**
   * @name authValue
   * @description Getter for easy access to auth subject value
   */
  public get authValue(): IAuth {
    return this.authSubject.value;
  }

  /**
   * @name login
   * @description Request login
   * @param {string} email
   * @param {string} password
   */
  login(email: string, password: string) {
    return this.http.post<IAuth>(`${environment.apiUrl}/api/v1/operators/users/login`, { email, password })
      .pipe(
        map((response: any) => {
          if (response && response.id) {
            this.user = response;
            localStorage.setItem('buslane-session', JSON.stringify(this.user));
            this.authSubject.next(this.user);
            return this.user;
          }

          throw new Error(response.errorMessage);
        }),
        catchError(error => {
          return throwError(error);
        }),
      );
  }

  /**
   * @name logout
   * @description Remove user from local storage and set current user to null
   */
  logout() {
    localStorage.removeItem('buslane-session');
    this.authSubject.next(null);
    this.router.navigate(['/auth/signin']);
  }

  /**
   * @name forgotPassword
   * @description forgot password base on email address
   */
  forgotPassword(email: string){
    return this.http.post<IAuth>(`${environment.apiUrl}/api/v1/operators/users/forgot-password`, { email });
  }

  /**
   * @name resetPassword
   * @description Reset password for forgot password
   */
  resetPassword(newPassword: string, confirmPassword: string, token: string){
    return this.http.post<IAuth>(`${environment.apiUrl}/api/v1/operators/users/reset-password/`.concat(token+""), { newPassword, confirmPassword });
  }
}
