import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './layouts/admin/admin.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthModule } from './pages/auth/auth.module';
import { SharedModule } from '@shared/shared.module';
import { HasRoleDirective } from '@app/helpers/directives/has-role.directive';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    BlankComponent,
    HasRoleDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
